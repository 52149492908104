@import "styles/variables";
.listEntry {
  padding: 13px 16px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  margin-top: -1px;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.name {
  font-weight: 600;
  text-align: left;
  align-self: center;
  flex-grow: 1;
}

.value {
  text-align: right;
  align-self: center;
  margin-left: 10px;
}

.spacer {
  flex-grow: 1;
}

.wrapper {
  align-self: center;
}

.rating {
  padding-left: 8px;
  padding-right: 8px;
}

.active {
  color: #48d3ac;
}

.inactive {
  color: #e5e5e5;
}
