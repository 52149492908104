@import "styles/variables";
.listLink {
  padding: 13px 16px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  margin-top: -1px;
  background: white;
  display: block;
  color: #494949;
  i {
    font-size: 30px;
    margin-top: -11px;
  }
}

.name {
  font-weight: 600;
  text-align: left;
}

.value {
  text-align: right;
  float: right;
}
