@import "styles/variables";
.listTitle {
  background: #f8f8f8;
  padding: 22px 16px 6px 16px;
}

.title {
  text-transform: uppercase;
  font-size: 14px;
  text-transform: uppercase;
}
