@import "styles/variables";
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.7);
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px 30px 40px 30px;
  background-color: white;
  width: 400px;
  max-width: 100%;
  overflow: auto;
  max-height: 100%;
}

.footer {
  margin-top: 30px;
}
