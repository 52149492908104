@import "styles/variables";
.wrapper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  background-color: white;
  color: #494949;

  .content {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .title {
    font-size: 12px;
  }

  strong {
    color: #48d3ac;
  }

  a {
    color: #494949;
    font-weight: 600;
  }

  img {
    margin: 0 auto;
    max-width: 100%;
    display: block;
    width: 500px;
    height: auto;
  }
}
