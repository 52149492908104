@import "styles/variables";
.listEntry {
  padding: 13px 16px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  margin-top: -1px;
  background: white;
}

.name {
  font-weight: 600;
  text-align: left;
}

.value {
  text-align: right;
  //float: right;
  margin-left: 10px;
  &.right {
    float: right;
  }
  word-wrap: break-word;
}

.checkbox {
  text-align: right;
  float: right;
  line-height: 1;
  color: #48d3ac;

  i {
    font-size: 44px;
    margin-top: -10px;
    display: block;
  }
}
