@import "styles/variables";
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  #root > div {
    background-color: red;
    height: 100%;
  }
}
