@import "styles/variables";
.filter {
  width: 100%;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
}

.filterWrapper {
  background: white;
  width: 600px;
  max-width: 100%;
  margin: 0 auto;
  height: 100%;
  overflow-y: scroll;
}

.filterButton {
  display: block;
  padding: 15px;
  border: 0;
  background: transparent;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-left: 5px solid;
  margin-top: -1px;
  width: 100%;
  position: relative;
  &.active {
    &:after {
      content: "\f3fd";
      font-family: "Ionicons";
      position: absolute;
      right: 15px;
      top: 10px;
      font-size: 30px;
      line-height: 30px;
    }
  }
}

.reset {
  text-align: center;
  margin-top: 20px;
}
