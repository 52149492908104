@import "styles/variables";
@import "../../styles/variables.scss";

.animal {
  height: 100%;
  background: white;
  &:after {
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    width: 1px;
    top: 0;
    margin-left: 22px;
    z-index: 0;
  }

  h1 {
    margin-top: 5px;
    margin-bottom: 0;
    font-size: 28px;
    word-wrap: break-word;
  }
}

.modalWrapper {
  text-align: center;

  h2 {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    font-weight: 600;
    color: #48d3ac;
  }

  h3 {
    margin: 0;
    margin-top: 30px;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
}

.header {
  width: 100%;
  z-index: 3;
  position: relative;
  background: white;
}

.headerContent {
  transition: opacity 0.1s ease-out;
  &.hidden {
    .showFilter {
      display: none;
    }
  }
}

.image {
  width: calc(100% + 30px);
  padding-bottom: calc(100% + 30px);
  background-size: cover;
  background-position: center center;
  margin-left: -15px;
  margin-right: -15px;
}

.feed {
  position: relative;
  padding-top: 30px;
  background: white;
  z-index: 2;
  &:after {
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    width: 1px;
    background: #e6e6e6;
    top: 0;
    margin-left: 22px;
  }
}

.showFilter {
  background: #f8f8f8;
  width: 100%;
  text-align: center;
  padding: 8px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.06);
  i {
    font-size: 22px;
    margin-left: 8px;
    font-size: 14px;
    margin-right: 2px;
  }
  span,
  i {
    line-height: 12px;
    vertical-align: middle;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
  }
}

.nickname {
  font-size: 14px;
  margin-bottom: 10px;
  display: inline-block;
}

.regNr {
  display: block;
  font-size: 14px;
}
