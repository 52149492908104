@import "styles/variables";
.wrapper {
  border-radius: 20px;
  border: 2px $lightGray solid;
  font-size: 16px;
  margin-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: $textGray;
  cursor: pointer;
  @media screen and (max-width: 375px) {
    font-size: 12px;
  }
}

.total {
  margin-right: 0px;
}

.disabled {
  color: white;
  background: $lightGray;
}

.active {
  background: $green;
  color: white;
  border: 2px $green solid;
}

.inactive {
  background: white;
  color: $textGray;
  border: 2px $green solid;
}
