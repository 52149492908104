@import "styles/variables";
.wrapper {
  margin: 0px 8px 16.5px 8px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 3px;
}

p {
  padding: 0px 8px 0px 8px;
}

.columnHeaders {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: $textGreen;
}

.resultList {
  list-style: none;
  div {
    &:nth-child(even) {
      background-color: white;
    }
    &:nth-child(odd) {
      background-color: $sampleGray;
    }
  }
}

.resultRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.resultText {
  text-align: right;
}

.title {
  padding-top: 17.5px;
  font-size: 16px;
}

.date {
  color: rgba(0, 0, 0, 0.54);
  padding-bottom: 14;
}

.messageTitle {
  color: $textGreen;
  padding-top: 15px;
  padding-bottom: 13px;
}

.message {
  padding-bottom: 12px;
}
