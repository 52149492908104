@import "styles/variables";
.header {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 15px;
  background: white;
  z-index: 1;
  position: relative;
  i {
    font-size: 25px;
    line-height: 25px;
    vertical-align: middle;
  }
  button {
    padding-left: 0;
    padding-right: 0;
  }
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px;
}

.wrapper {
  overflow: hidden;
  padding-bottom: 2px;
}

.title {
  font-weight: 300;
  line-height: 25px;
  vertical-align: middle;
  align-self: center;
  margin-top: 4px;
  font-size: 14px;
  word-wrap: break-word;
}

.details {
  font-weight: 600;
  font-size: 13px;
  line-height: 25px;
  margin-top: 2px;
  color: #48d3ac;
}
