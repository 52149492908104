@import "styles/variables";
.info {
  background-color: white;
  height: 180px;
  padding: 16px 9px 16px 24px;
  white-space: pre-wrap;
  border: 1px solid $sampleGray;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 24px;
  padding-top: 16px;
  padding-left: 16px;
}

.delimiter {
  height: 10px;
  background-color: $green;
  margin-bottom: 16.5px;
}
