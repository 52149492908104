@import "styles/variables";
.card {
  position: relative;
  padding-bottom: 40px;
  margin-left: 45px;
  z-index: 2;
  padding-right: 20px;
  color: #494949;
  &:hover {
    .bullet {
      transform: scale(1.4);
    }
    .bulletBorder {
      transform: scale(1.8);
    }
  }
}

.content {
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardTitle {
  font-size: 21px;
  margin: 0;
  display: inline-block;
  vertical-align: bottom;
  line-height: 21px;
  margin-bottom: 10px;
  font-weight: 600;
}

.date {
  float: right;
  vertical-align: bottom;
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
}

.timeline {
  position: absolute;
  width: 15px;
  left: -30px;
  top: 0;
  bottom: 0;
  margin-top: 3px;
}

.bulletBorder {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  opacity: 0.3;
  transition: transform 0.1s ease-out;
}

.bullet {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 3px;
  transition: transform 0.1s ease-out;
}

.reminder {
  font-weight: 500;
  i {
    margin-left: 10px;
  }
}
