@import "styles/variables";
body {
  font-family: "Open Sans";
  background: white;
  -webkit-font-smoothing: antialiased;
  background: #ddd;
  color: #494949;
}

html,
body {
  height: 100%;
}

button {
  background: transparent;
  border: 0;
  &:focus {
    outline: none;
  }
}

a {
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
}

.wrapper {
  width: 600px;
  max-width: 100%;
  margin: 0 auto;
  background: white;
  height: 100%;
  & > div {
    height: 100%;
  }
}

:global {
  .container {
    width: 600px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
  }
}
