@import "styles/variables";
.slider {
  width: 100%;
  overflow-x: hidden;
  position: relative;
}

.slides {
  height: 100%;
  position: relative;
}

.slide {
  display: inline-block;
  position: relative;
  height: 0;
  video {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
    background: black;
    cursor: pointer;
  }
  &:hover {
    .play {
      transform: translate(-50%, -50%) scale(1.1);
    }
  }
}

.thumbs {
  background: white;
}

.thumbSlide {
  display: inline-block;
  width: calc(20% - 20px);
  height: 0;
  padding-bottom: calc(20% - 20px);
  background-size: cover;
  background-position: center center;
  border-radius: 4px;
  margin: 14px 10px;
  cursor: pointer;
  background-color: #eee;
}

.left,
.right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 4;
  color: white;
  font-size: 20px;
}

.left {
  left: 10px;
}

.right {
  right: 10px;
}

.dots {
  position: absolute;
  bottom: 10px;
  z-index: 2;
  text-align: center;
  width: 100%;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ccc;
  padding: 0;
  border: 0;
  margin: 0 5px;
  cursor: pointer;
  &.active {
    background: white;
  }
}

.image {
  width: 100%;
  padding-bottom: 100%;
  height: 0;
  background-size: cover;
  background-position: center center;
}

.play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.1s ease;
  font-size: 100px;
  color: white;
  z-index: 3;
  cursor: pointer;
}

.documentWithTooltip {
  background-image: url(../assets/document.svg);
  display: inline-block;
  width: calc(20% - 20px);
  height: 0;
  padding-bottom: calc(20% - 20px);
  background-size: cover;
  background-position: center center;
  border-radius: 4px;
  margin: 14px 10px;
  cursor: pointer;
  background-color: #ffffff;
  position: relative;
}

.documentWithTooltip .tooltipText {
  visibility: hidden;
  width: 240px;
  background-color: white;
  color: #000000;
  text-align: center;
  border-radius: 6px;
  border-color: #000000;
  border-style: solid;
  border-width: 1px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-top: 5px;
  margin-left: -120px;
}

.documentWithTooltip:hover .tooltipText {
  visibility: visible;
}