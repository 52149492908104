@import "styles/variables";
.listEntry {
  padding: 13px 16px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  margin-top: -1px;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.name {
  font-weight: 600;
  text-align: left;
  align-self: center;
  width: 25%;
}

.sliderTrack {
  margin-left: 10px;
  border-radius: 2px;
  height: 4px;
  width: 100%;
  flex-grow: 1;
  align-self: center;
  background: #e5e5e5;
}

.activeSliderTrack {
  margin: 0px;
  @extend .sliderTrack;
  background: #48d3ac;
}

.value {
  text-align: right;
  align-self: center;
  margin-left: 10px;
  width: 15%;
}
