@import "styles/variables";
.wrapper {
  width: 100%;
  height: 100vh;
  background: white;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
  min-width: 30%;
  height: 100%;
  padding: 28px;
}

.text {
  font-size: 16px;
  white-space: pre-wrap;
  padding-bottom: 20px;
}

.title {
  padding-top: 30px;
  font-weight: bold;
  font-size: 28px;
  text-align: left;
}

.image {
  opacity: 0.7;
  background-image: url(../assets/horse.jpg);
  @media (max-width: 768px) {
    height: 100vh;
    width: 100%;
  }
  height: 100vh;
  width: 90%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
