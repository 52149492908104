@import "styles/variables";
.border {
  height: 10px;
  width: 100%;
}

.metaDescription {
  text-align: center;
  padding: 15px;
}

.reminder,
.description {
  padding: 20px 0;
  line-height: 22px;
  vertical-align: middle;
  i {
    font-size: 22px;
  }
  .reminderIcon {
    font-size: 16px;
    color: red;
  }
}

.reminder {
  background: #f9f9f9;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.description {
  background: white;
}

.history {
  font-size: 12px;
  .entry {
    padding: 10px 0;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    margin-top: -1px;
    &:hover {
      background: #f9f9f9;
    }
    a {
      color: #494949;
    }
  }
}

.sectionTitle {
  background: #f9f9f9;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 20px 15px 5px 15px;
  font-size: 11px;
  text-transform: uppercase;
}
