@import "styles/variables";
.header {
  margin-left: 15px;
  margin-right: 15px;
}

.title {
  font-weight: bold;
  font-size: 18px;
}

.tabs {
  display: flex;
  justify-content: space-between;
}

.miscTabs {
  display: flex;
  justify-content: flex-start;
}

.rationDescription {
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
  color: gray;
  font-size: 12px;
}

.partial-ration-enter {
  opacity: 0.01;
}

.partial-ration-enter.partial-ration-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.partial-ration-leave {
  opacity: 1;
}

.partial-ration-leave.partial-ration-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
