@import "styles/variables";
.button {
  background: #48d3ac;
  border: none;
  color: white;
  padding: 10px 40px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
}
